








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, PropType } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import HomeWidget from '@/components/home/widgets/HomeWidget.vue';
// Import types ------------------------------------------------------------------------------------
import type { Widget } from '@/pages/PageHome.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  components: { HomeWidget },
  name: 'HomeWidgets',
  props: {
    stuffWidget: {
      type: Object as PropType<Widget>,
      required: true
    }
  },
  setup(properties) {
    const widgets: Widget[] = [
      // {
      //   title: 'home.widgets.discover-pro-plans',
      //   description: 'home.widgets.discover-pro-plans.desc',
      //   src: 'dashboard-card-discover-plans.png',
      //   actions: [
      //     {
      //       name: 'commons.actions.upgrade',
      //       href: 'https://www.digitsolepro.com/pricing',
      //       tracker: 'Discover Pro Plans'
      //     }
      //   ]
      // },
      {
        title: 'home.widgets.digitsole-pro-kit',
        description: 'home.widgets.digitsole-pro-kit.desc',
        src: {
          name: 'dashboard-card-pro-kit.png',
          version: '7LYOoX8CzowIRdc8BIvC0D4ugi0Uwndw'
        },
        actions: [
          {
            name: 'commons.actions.discover',
            href: 'https://www.digitsole.com/product',
            tracker: 'Discover Baliston Health Kit'
          }
        ]
      },
      {
        ...properties.stuffWidget
      }
    ];
    return {
      // Values
      widgets
    };
  }
});
